const menu = [
  {
    id: 1,
    title: 'EYEBROWS',
    category: 'WAXING',
    price: 18,
    img: './images/item-1.jpeg',
    desc: ``,
  },
  {
    id: 2,
    title: 'LIP',
    category: 'WAXING',
    price: 12,
    img: './images/item-2.jpeg',
    desc: ``,
  },
  {
    id: 3,
    title: 'CHIN',
    category: 'WAXING',
    price: 12,
    img: './images/item-3.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'UNDERARM',
    category: 'WAXING',
    price: 25,
    img: './images/item-4.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: 'FULL FACE',
    category: 'WAXING',
    price: 60,
    img: './images/item-5.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'HALF ARM',
    category: 'WAXING',
    price: 40,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'FULL ARM',
    category: 'WAXING',
    price: 55,
    img: './images/item-7.jpeg',
    desc: ``,
  },
  {
    id: 8,
    title: 'HALF LEG',
    category: 'WAXING',
    price: 50,
    img: './images/item-8.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: 'FULL LEG',
    category: 'WAXING',
    price: 65,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'BACK AND SHOULDER',
    category: 'WAXING',
    price: '65',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'CHEST',
    category: 'WAXING',
    price: '55',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'BIKINI LINE',
    category: 'WAXING',
    price: '45',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'BRAZILIAN',
    category: 'WAXING',
    price: '70',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'EYEBROWS',
    category: 'TINTING',
    price: '20',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'EYELASH',
    category: 'TINTING',
    price: '25',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'TRIO-COMBO (Eyebrow wax + tint + eyelash tint)',
    category: 'TINTING',
    price: '60',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 15,
    title: 'FULL SET CLASSIC',
    category: 'EYELASH EXTENSION',
    price: '65-75',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 16,
    title: 'FULL SET VOLUME',
    category: 'EYELASH EXTENSION',
    price: '85-95',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 17,
    title: 'INFILL CLASSIC',
    category: 'EYELASH EXTENSION',
    price: 'from 55',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 18,
    title: 'INFILL VOLUME',
    category: 'EYELASH EXTENSION',
    price: 'From 65',
    img: './images/item-9.jpeg',
    desc: ``,
  },
];
export default menu;
