import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";

import img1 from "../../assets/Nfts/bighead.svg";
import img2 from "../../assets/Nfts/bighead-1.svg";
import img3 from "../../assets/Nfts/bighead-2.svg";
import img4 from "../../assets/Nfts/bighead-3.svg";
import img5 from "../../assets/Nfts/bighead-4.svg";
import img6 from "../../assets/Nfts/bighead-5.svg";
import img7 from "../../assets/Nfts/bighead-6.svg";
import img8 from "../../assets/Nfts/bighead-7.svg";
import img9 from "../../assets/Nfts/bighead-8.svg";
import img10 from "../../assets/Nfts/bighead-9.svg";
import ETH from "../../assets/icons8-ethereum-48.png";

import taupo1 from '../../assets/Taupo/taupo1.JPG';
import taupo2 from '../../assets/Taupo/taupo2.jpg';
import taupo3 from '../../assets/Taupo/taupo3.JPG';
import taupo4 from '../../assets/Taupo/taupo4.JPG';
import taupo5 from '../../assets/Taupo/taupo5.jpg';
import taupo6 from '../../assets/Taupo/taupo6.JPG';
import taupo7 from '../../assets/Taupo/taupo7.JPG';
import taupo8 from '../../assets/Taupo/taupo8.jpg';
import taupo9 from '../../assets/Taupo/taupo9.jpg';
import taupo10 from '../../assets/Taupo/taupo10.jpg';
import taupo11 from '../../assets/Taupo/taupo11.JPG';
import taupo12 from '../../assets/Taupo/taupo12.jpg';
import taupo13 from '../../assets/Taupo/taupo13.JPG';
import taupo14 from '../../assets/Taupo/taupo14.jpg';
import taupo15 from '../../assets/Taupo/taupo15.jpg';
import taupo16 from '../../assets/Taupo/taupo16.JPG';
import background from "./pink1.jpg";
import logo from "../../assets/taupo_logo.png";

const Section = styled.section`
  min-height: 50vh;
  width: 100vw;
  background-color: ${(props) => props.theme.darkblue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  & > *:first-child {
    animation-duration: 20s;

    @media (max-width: 30em) {
      animation-duration: 15s;
    }
  }
  & > *:last-child {
    animation-duration: 15s;
    @media (max-width: 30em) {
      animation-duration: 10s;
    }
  }
`;
const move = keyframes`
0%{ transform: translateX(100%)   };
100%{ transform: translateX(-100%)   }

`;

const Row = styled.div`
  /* background-color: lightblue; */
  white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem 0;
  display: flex;

  animation: ${move} linear infinite ${(props) => props.direction};
`;
const ImgContainer = styled.div`
  width: 15rem;
  margin: 0 1rem;
  background-color:${(props) => props.theme.darkblue};

  border-radius: 20px;
  cursor: pointer;

  @media (max-width: 48em) {
    width: 12rem;
  }
  @media (max-width: 30em) {
    width: 10rem;
  }

  img {
    width: 100%;
    height: 80%;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.8rem 1rem;
  background-color: ${(props) => props.theme.darkblue};
  border: 2px solid ${(props) => props.theme.lightblue};;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  span {
    font-size: ${(props) => props.theme.fontsm};
    color: ${(props) => props.theme.lightblue};
    font-weight: 600;
    line-height: 1.5rem;
    
    @media (max-width: 30em) {
      font-size: ${(props) => props.theme.fontxs};
    }
  }

  h1 {
    font-size: ${(props) => props.theme.fontmd};
    color: ${(props) => props.theme.body};
    font-weight: 600;

    @media (max-width: 30em) {
      font-size: ${(props) => props.theme.fontxs};
    }
  }
 @media (max-width: 20em) {
    img {
      max-height: 30px;
      height: auto;
      width: auto;
    }
  }
`;

const Price = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 1rem;
    height: auto;
  }
`;

const NftItem = ({ img, number = 0, price = 0, passRef }) => {
  let play = (e) => {
    passRef.current.style.animationPlayState = "running";
  };
  let pause = (e) => {
    passRef.current.style.animationPlayState = "paused";
  };

  return (
    <ImgContainer onMouseOver={(e) => pause(e)} onMouseOut={(e) => play(e)}>
      <img width={500} height={400} src={img} alt="The Weirdos" />
      <Details>
        <div>
        <img src={logo} alt="Taupo" />
          {/* <h1>#{number}</h1> */}
        </div>

        <div>
          {/* <span>Price</span> */}
          <Price>
            {/* <img width={200} height={200}  src={ETH} alt="ETH" /> */}
            {/* <h1>{Number(price).toFixed(1)}</h1> */}
          </Price>
        </div>
      </Details>
    </ImgContainer>
  );
};

const Showcase = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);

  return (
    <Section
      id="photos"
    >
      <Row direction="none" ref={Row1Ref}>
        <NftItem img={taupo1} number={852} price={1} passRef={Row1Ref} />
        <NftItem img={taupo2} number={123} price={1.2} passRef={Row1Ref} />
        <NftItem img={taupo2} number={456} price={2.5} passRef={Row1Ref} />
        <NftItem img={taupo2} number={666} price={3.5} passRef={Row1Ref} />
        <NftItem img={taupo2} number={452} price={4.7} passRef={Row1Ref} />
      </Row>
      <Row direction="reverse" ref={Row2Ref}>
        <NftItem img={taupo2} number={888} price={1.2} passRef={Row2Ref} />
        <NftItem img={taupo2} number={211} price={3.2} passRef={Row2Ref} />
        <NftItem img={taupo2} number={455} price={1.8} passRef={Row2Ref} />
        <NftItem img={taupo2} number={456} price={5.1} passRef={Row2Ref} />
        <NftItem img={taupo2} number={865} price={3.7} passRef={Row2Ref} />
      </Row>
    </Section>
  );
};

export default Showcase;
