import React from "react";
import InstaFeeds from "./InstaComponent/InstaFeeds";
import background from "./bluestar.jpg";
import { ins_url } from "../../fresha_url";

const Instagram = () => {
  return (
    <div
      id="instagram"
      style={{
        background: `linear-gradient(to bottom, rgba(0, 11, 24, 0.6), rgba(0, 11, 24, 0.9)), url(${background})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: '50vh'
      }}
    >
      <h1 className="instagram-title">Follow us on Instagram</h1>
      <a
        href={ins_url}
        target="_blank"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          marginBottom: "2rem",
          textDecoration: "none",
          color: "#fff",
        }}
      >
        #taupoluxurynails
      </a>

      <InstaFeeds
        // token="IGQWRQVTVVcUZA1dFp0R2ZAHTGUybE9JbWhuNTRRVUVLd0tnRmEweW1DTXlPaGt3OEhGUXZAoUTlLeWtpYkhERHc3QWMtNFhBZAWFUTTNzTHNjMkJmMzRHTDdpQ0VDekN6cmhSSENtQjgtYlFEYjc3YWpEN3lCTTBxbHMZD"
      //  date 27 Mar 24
        token="IGQWROTE9rMFJaUmlNX1JtOUl6WURKN0N4TC1pT05KZAW40NFV3UzVHUUVuc3ZAQcEhaUUxsTGhQNExmZAGNBUUNxY0FEMUZAUbGxZAUERTaHFxbnJqZATFab0dGSnBKWWhiS1ZATRTF5WGpEVlNQYmM0c1pXdHc4ZA0V6aEEZD"
        limit={12}
      />
    </div>
  );
};

export default Instagram;
