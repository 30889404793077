import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from "../assets/taupo_logo.png";

const LogoText = styled.h1`
font-family: 'Akaya Telivigala', cursive;
font-size: ${props => props.theme.fontxxxl};
// color: ${props => props.theme.text};
transition: all 0.2s ease;

text-align: center;
margin-bottom: 2rem;
font-size: clamp(2.1rem, 7vw, 3rem);
/* color: #fff; */
background: -webkit-linear-gradient(135deg, #fe036a, #f5347f);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

display: flex;
align-items: center;
height: auto;
cursor: pointer;
// border: 1px solid green;
img {
  // margin-right: 0.5rem;
  max-width: 500px;
  margin-top: 3rem;
  height: 50px;
  width: auto;
}

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size: ${props => props.theme.fontxxl};
img {
  // margin-right: 0.5rem;
  max-width: 200px;
  margin-top: 3rem;
  height: auto;
  width: auto;
}

}
`

const Logo = () => {
  return (
    <LogoText>
        <Link to="/">
        <img src={logo} alt="CodeBucks" />
        </Link>
    </LogoText>
  )
}

export default Logo