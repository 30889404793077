import React, { lazy, Suspense } from "react";
import styled from "styled-components";
// import Banner from './Banner'
import Logo from "./Logo";

import Facebook from "../Icons/Facebook";
import Instagram from "../Icons/Instagram";
import Twitter from "../Icons/Twitter";
import LinkedIn from "../Icons/LinkedIn";
import Loading from "./Loading";
import background from "./sections/blue2.jpg";
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { fb_url, ins_url } from "../fresha_url";

const Banner = lazy(() => import("./Banner"));

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  // background: red;
  position: relative;
  color: ${(props) => props.theme.body};
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontlg};

  border-bottom: 1px solid ${(props) => props.theme.body};

  @media (max-width: 48em) {
    width: 90%;

    h1 {
      font-size: ${(props) => props.theme.fontxxxl};
    }
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem auto;

  & > * {
    padding-right: 1.5rem;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
const MenuItems = styled.ul`
  font-family: "montserrat", handwriting;
  list-style: none;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 48em) {
    display: none;
  }
`;
const Item = styled.li`
  width: fit-content;
  cursor: pointer;
  font-family: "montserrat", handwriting;
  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.body};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
`;

const Bottom = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontlg};

  a {
    text-decoration: underline;
  }
  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;
    font-size: ${(props) => props.theme.fontmd};

    span {
      margin-bottom: 1rem;
    }
  }
`;

const Title_contact = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.lightblue};
  
  padding: 1rem 2rem 0 2rem;
  z-index: 10;
  text-transform: capitalize;
  margin: 0rem auto;
  // border: 1px solid green;

  text-shadow: 1px 1px 2px ${(props) => props.theme.body};

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
    text-align: center;
    width: 100%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    text-align: center;
    padding: 2rem 0rem 0 0rem;
    width: 100%;
    // border: 1px solid green;
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.9)`};
  font-family: "montserrat", handwriting;
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding: 2rem 2rem 0 2rem;
  // border: 1px solid green;

  width: 100%;
  align-self: flex-start;
  text-align: center;
  text-transform: capitalise;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
`;

const Footer = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Section id="contactus"
    style={{
      background:  ` url(${background}) center`,
  
  // background: "#F3ADB8",
  }}
    
    >
      <Suspense fallback={<Loading />}>
        <Banner />{" "}
      </Suspense>
      <Title_contact><a href="tel:+6472817009">📱 072 817 009</a></Title_contact>
      <Title_contact><a href="mailto:TAUPOLUXURYNAILS@GMAIL.COM">📧 Taupoluxurynails@gmail.com</a></Title_contact>
      
      <SubTitle> Come to us and enjoy the best moment at Taupo Luxury Nails!</SubTitle>
      <Container>
        
        <Left>
          <Logo />
          <IconList>
            <a
              href={fb_url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <FaFacebook size={30}/>
            </a>
            <a
              href={ins_url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <FaInstagram size={30}/>
            </a>
          </IconList>
        </Left>
        <MenuItems>
          <Item onClick={() => scrollTo("home")}>Home</Item>
          <Item onClick={() => scrollTo("about")}>About Us</Item>
          <Item onClick={() => scrollTo("nails")}>Nails Services</Item>
          <Item onClick={() => scrollTo("beauty")}>Beauty Services</Item>
          <Item onClick={() => scrollTo("instagram")}>Instagram</Item>
        </MenuItems>
      </Container>
      <Bottom>
        <span>&copy; 2023 Taupo Luxury Nails. All rights reserved.</span>
        <span>
          Made with &#10084; by{" "}
          <a
            href="https://miraelite.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mira Elite
          </a>
        </span>
      </Bottom>
    </Section>
  );
};

export default Footer;
