import React, { lazy, Suspense } from 'react'
import styled, { keyframes } from 'styled-components'
// import CoverVideo from '../CoverVideo'
// import TypeWriterText from '../TypeWriterText'
import RoundTextBlack from '../../assets/Rounded-Text-Black.png';
import Loading from '../Loading';
import taupo_c from '../../assets/taupo_c.png';
import background from "./blue2.jpg";

const Carousel = lazy(() => import("../Carousel"));

const CoverVideo = lazy(() => import('../CoverVideo'));
const TypeWriterText = lazy(() => import('../TypeWriterText'));

const Section = styled.section`

  background-size: cover;
  -webkit-animation: slidein 20s;
  animation: slidein 20s;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;

  @media screen and (max-width: 768px) {
    padding-top: 00px;
    margin-top: 000px;
  }

  @-webkit-keyframes slidein {
    from {
      background-position: top;
      background-size: 3000px;
    }
    to {
      background-position: -100px 0px;
      background-size: 2750px;
    }
  }

  @keyframes slidein {
    from {
      background-position: top;
      background-size: 3000px;
    }
    to {
      background-position: -100px 0px;
      background-size: 2750px;
    }
  }
`

const Container = styled.div`
width: 75%;
min-height: 80vh;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  width: 85%;
}
@media (max-width: 48em) {
  flex-direction: column-reverse;
  width: 100%;
  &>*:first-child{
    width: 100%;
    margin-top: 2rem;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const rotate = keyframes`
100%{
  transform: rotate(1turn);
}
`
const Round = styled.div`
position: absolute;
bottom: 2rem;
right: 90%;
width: 7rem;
height: 7rem;
// border: 1px solid ${props => props.theme.text};
border-radius: 50%;

img{
  width: 100%;
  height: auto;
  animation: ${rotate} 12s linear infinite reverse ;
}
@media (max-width: 64em) {
  width: 4rem;
  height: 4rem;
  left: none;
  right: 2rem;
  bottom: 100%;
  display: none;
}
@media (max-width: 48em) {
  display: none;

  right: 1rem;
}
`



const Circle = styled.span`
width: 3.5rem;
height: 3.5rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);

// background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
font-size:${props => props.theme.fontxl};

@media (max-width: 64em) {
  width: 2rem;
  height: 2rem;
font-size:${props => props.theme.fontlg};
display: none;

}

`

const Home = () => {
  return (
    <Section id="home"
    
    style={{
      background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)), url(${background}) center`

    }}
    
    >
      <Container>
      <Box>
        <Suspense fallback={<Loading />}>
          <TypeWriterText /></Suspense>
        </Box>
        {/* <Box>
        <Suspense fallback={<Loading />}>
          <CoverVideo /></Suspense>
          
        </Box> */}
        {/* <Box> 
        <Suspense fallback={<Loading />}>
          <Carousel /> </Suspense> </Box> */}
        
        <Round>
        <Circle>
          &#x2193; 
        </Circle>
          <img width={300} height={300} src={taupo_c} alt="NFT" />
        </Round>

      </Container>
    </Section>
  )
}

export default Home