const menu = [
  {
    id: 1,
    title: 'NORMAL COLOR ',
    category: 'SPA PEDICURE',
    price: 43,
    img: './images/item-1.jpeg',
    desc: '',
  },
  {
    id: 2,
    title: 'GEL/SHELLAC COLOR',
    category: 'SPA PEDICURE',
    price: 55,
    img: './images/item-2.jpeg',
    desc: `Enjoy and treat yourself with all special treatments for feet including:
    •	Nail cutting and shaping
    •	Deep-clean cuticle work
    •	Exfoliating your feet with our signature sea salt scrub
    •	Callus Removal - effectively buffing away dry skin to reveal fresher, more vibrant
    •	Hot stone massage – effectively helping relieve muscle tension and pain and reducing stress and anxiety
    •	Your choice of favourite nail polish
    `,
  },
  {
    id: 3,
    title: 'NORMAL COLOR',
    category: 'QUICK PEDICURE',
    price: 28,
    img: './images/item-4.jpeg',
    desc: `
    `,
  },
  {
    id: 4,
    title: 'GEL/SHELLAC COLOR',
    category: 'QUICK PEDICURE',
    price:40,
    img: './images/item-5.jpeg',
    desc: `This service includes:
    •	Nail cutting, shaping and buffing
    •	Your choice of favourite nail polish    
    `,
  },
  {
    id: 5,
    title: 'NORMAL COLOR',
    category: 'FULL MANICURE',
    price: 30,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'GEL/SHELLAC COLOR',
    category: 'FULL MANICURE',
    price: 42,
    img: './images/item-7.jpeg',
    desc: `Treat yourself and feel the difference with our high-quality service for hand including:
    •	Nail cutting and shaping;
    •	Deep-clean cuticle work
    •	Hand massage with our signature lotion
    •	Choice of your favourite nail polish.
    `,
  },
  {
    id: 7,
    title: 'NORMAL COLOR',
    category: 'QUICK MANICURE',
    price: 20,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 8,
    title: 'GEL/SHELLAC COLOR',
    category: 'QUICK MANICURE',
    price: 32,
    img: './images/item-9.jpeg',
    desc: `This service includes:
    •	Nail cutting, shaping and buffing
    •	Your choice of favourite nail polish
    `,
  },
  {
    id: 9,
    title: 'FULL SET WITH GEL/SHELLAC COLOR',
    category: 'ACRYLIC NAILS',
    price: '68',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'FULL SET WITH NORMAL COLOR',
    category: 'ACRYLIC NAILS',
    price: '60',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'BACKFILL WITH GEL/SHELLAC COLOR',
    category: 'ACRYLIC NAILS',
    price: '55',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'BACKFILL WITH NORMAL COLOR',
    category: 'ACRYLIC NAILS',
    price: '50',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'ON NATURAL NAILS',
    category: 'SNS NAILS',
    price: '55',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 14,
    title: 'REMOVAL + REDO',
    category: 'SNS NAILS',
    price: '60',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 15,
    title: 'WITH TIPS (EXTENSION)',
    category: 'SNS NAILS',
    price: '68',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 16,
    title: 'FULL SET',
    category: 'GEL EXTENSION',
    price: '65',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 17,
    title: 'BACKFILL',
    category: 'GEL EXTENSION',
    price: '60',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 18,
    title: 'REMOVAL + REDO',
    category: 'GEL EXTENSION',
    price: '70',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 19,
    title: 'ON NATURAL NAILS',
    category: 'BUILDER GEL/ HARD GEL',
    price: '60',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 20,
    title: 'REMOVAL + REDO',
    category: 'BUILDER GEL/ HARD GEL',
    price: '65',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 21,
    title: 'WITH TIPS (EXTENSION)',
    category: 'BUILDER GEL/ HARD GEL',
    price: '68',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 22,
    title: 'BACKFILL',
    category: 'BUILDER GEL/ HARD GEL',
    price: '60',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 23,
    title: 'COLOR OMBRE',
    category: 'NAIL ART/ DESIGN',
    price: '15',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 24,
    title: 'GLITTER OMBRE',
    category: 'NAIL ART/ DESIGN',
    price: '5',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 25,
    title: 'CROME',
    category: 'NAIL ART/ DESIGN',
    price: '10',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 26,
    title: 'CATEYE',
    category: 'NAIL ART/ DESIGN',
    price: '5',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 27,
    title: 'NAIL ART',
    category: 'NAIL ART/ DESIGN',
    price: '$5/nail',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 28,
    title: 'NAIL ART',
    category: 'NAIL ART/ DESIGN',
    price: '$30/ set',
    img: './images/item-9.jpeg',
    desc: ``,
  },
];
export default menu;
