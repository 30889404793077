import React, { lazy, Suspense } from "react";
import styled, { ThemeProvider } from "styled-components";
// import Carousel from '../Carousel'
import Button from "../Button";
import { dark, light } from "../../styles/Themes";
import Loading from "../Loading";
import "./about_button.css";
import { fesha_url } from "../../fresha_url";
import background from "./pink.jpg";
import logo from "../../assets/taupo_logo.png";

const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.darkblue};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }
  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;
const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  // color: ${(props) => props.theme.lightblue};
  align-self: center;
  text-align: center;
  width: 80%;
  margin: 0 auto;

  background: -webkit-linear-gradient(
    135deg,
    ${(props) => props.theme.lightblue},
    ${(props) => props.theme.body}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxl};
    img {
      max-width: 330px;
      height: auto;
      width: auto;
    }
  }
`;
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.body};
  font-family: "montserrat", handwriting;
  align-self: center;
  text-align: center;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;
  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  font-family: "montserrat", handwriting;
  align-self: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  align-self: flex-start;

  @media (max-width: 48em) {
    width: 100%;
    align-self: center;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;

const About = () => {
  return (
    <Section id="about">
      <Container>
        <Box>
          <Suspense fallback={<Loading />}>
            <Carousel />{" "}
          </Suspense>{" "}
        </Box>
        <Box>
          <Title>
            Welcome To <br />
            <img src={logo} alt="Taupo" />
          </Title>
          <SubTextLight>
            We are always trying to be innovative with design and trends, always
            up to date with what the industry has to offer. Since our doors
            opened, we strive to provide each and every client with the most
            enjoyable and relaxing manicure and pedicure services available. Our
            trained staff will pamper you and make your hands, feet, and nails
            look their best.{" "}
          </SubTextLight>
          {/* <SubTextLight>
            Besides, understanding that sanitary and safety are clients’ top
            concerns, we try our best to guarantee clients’ health. Our
            implements, equipment, and electrical instruments are always
            thoroughly cleaned and subjected to an approved sanitizing and
            disinfecting process before being reused. All pedicure procedures
            are performed with liner protection.
          </SubTextLight> */}
          <SubText>
          Come to us and enjoy the best moment at Taupo Luxury Nails!
          </SubText>
          <ButtonContainer>
            <a href={fesha_url} target="_blank">
              <div class="centered">
                <div class="button_about glass3 clear">
                  BOOK NOW
                  <div class="glass3hover">BOOK NOW</div>
                  <div class="glass3ref"></div>
                  <div class="glass3hi"></div>
                  <div id="hi3" class="glass3hi"></div>
                  <div id="hi3" class="glass3hi"></div>
                </div>
              </div>
            </a>
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  );
};

export default About;
